import React, { useState, useEffect } from "react";
import "./ShowsSection.css";

const ShowsSection = () => {
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Fetch the XML data using AllOrigins to bypass CORS
    fetch('https://api.allorigins.win/get?url=' + encodeURIComponent('https://kintosol.com/events/feed'))
      .then(response => response.json())
      .then(data => {
        const base64Content = data.contents.split(",")[1];
        const decodedContent = atob(base64Content);

        const parser = new DOMParser();
        const xml = parser.parseFromString(decodedContent, 'text/xml');

        const items = xml.getElementsByTagName('item');
        const parsedEvents = Array.from(items).map(item => {
          const titleNode = item.getElementsByTagName('title')[0];
          const title = titleNode ? titleNode.textContent : 'No title';

          const descriptionNode = item.getElementsByTagName('description')[0];
          const description = descriptionNode ? descriptionNode.textContent.trim() : 'No description';

          // Extract the event date from the description
          const eventDateMatch = description.match(/Event date:\s*(\w+\s+\d+)/);
          const eventDate = eventDateMatch ? new Date(eventDateMatch[0].replace('Event date: ', '')) : 'No date';

          const linkNode = item.getElementsByTagName('link')[0];
          const link = linkNode ? linkNode.textContent : '#';

          return {
            title,
            location: description,
            date: eventDate,
            link,
          };
        });

        setEvents(parsedEvents);
        setIsLoading(false); // Stop loading once data is fetched
      })
      .catch(err => {
        console.error('Error:', err);
        setIsLoading(false); // Stop loading even if there's an error
      });
  }, []);

  const formatEventDate = (date) => {
    if (date === 'No date') return date;
    return {
      day: date.getDate(),
      month: date.toLocaleString('default', { month: 'short' }).toUpperCase(),
    };
  };

  return (
    <div className="shows-section-container">
      <div className="shows-section-header">
        <h2 className="shows-section-heading">Upcoming</h2>
        <h1 className="shows-section-main-title">SHOWS</h1>
      </div>

      {isLoading ? (
        <div className="shows-section-loading">Loading events...</div>
      ) : (
        <ul className="shows-section-list">
          {events.length > 0 ? (
            events.map((event, index) => {
              const formattedDate = formatEventDate(event.date);
              return (
                <li key={index} className="shows-section-item">
                  <div className="shows-section-date">
                    <div className="shows-section-day">{formattedDate.day}</div>
                    <div className="shows-section-month">{formattedDate.month}</div>
                  </div>
                  <div className="shows-section-info">
                    <h3 className="shows-section-title">{event.title}</h3>
                    <p className="shows-section-location">{event.location}</p>
                  </div>
                  <div className="shows-section-ticket-button-container">
                    <a href={event.link} target="_blank" rel="noopener noreferrer">
                      <button className="shows-section-ticket-button">Tickets</button>
                    </a>
                  </div>
                </li>
              );
            })
          ) : (
            <p>No upcoming events found.</p>
          )}
        </ul>
      )}
    </div>
  );
};

export default ShowsSection;